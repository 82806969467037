import firebase from 'gatsby-plugin-firebase';
import { I18nField } from '../models/i18n-field';
import { CreatePaymentDTO, CreatePaymentRO } from '../models/payment.model';

export interface ProductModel {
  title: I18nField<string>;
  price: PriceModel;
}

export interface PriceModel {
  adult: number;
  child: number;
  adultExtended: number;
  childExtended: number;
}

class ProductService {
  products: { [product: string]: ProductModel } = {};

  async getPrice(product: string): Promise<PriceModel> {
    const productObj = await this.getProduct(product);
    return productObj.price;
  }

  async getProduct(product: string): Promise<ProductModel> {
    if(this.products[product]) {
      return this.products[product];
    } else {
      const productObj = await firebase.app().firestore().collection('products').doc(product).get();
      this.products[product] = productObj.data() as ProductModel;
      return this.products[product]
    }
  }

  async createPaymentIntent(createPaymentDTO: CreatePaymentDTO): Promise<CreatePaymentRO> {
    try {
      const { data} = await firebase.app().functions('europe-west3').httpsCallable('createPaymentIntent')(createPaymentDTO);
      return {
        success: true,
        clientSecret: data.clientSecret
      };
    } catch(err) {
      return { success: false, message: err.message };
    }
  }
}

let productServiceInstance = new ProductService();
export function getProductService() {
  return productServiceInstance;
}