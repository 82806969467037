import Footer from '@/src/components/footer/footer';
import Header from '@/src/components/header/header';
import Navbar from '@/src/components/navbar/navbar';
import { Pass } from '@/src/components/pass/pass';
import { SignupFunnelCard } from '@/src/components/signup-funnel-card/signup-funnel-card';
import { TranslationProvider } from '@/src/contexts/translation.context';
import useAuthStatus from '@/src/hooks/auth-status.hook';
import { PassModel } from '@/src/models/pass.model';
import { getPassService } from '@/src/services/pass.service';
import { getProductService, ProductModel } from '@/src/services/product.service';
import { TranslationService } from '@/src/services/translation.service';
import { PageProps } from 'gatsby';
import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { mergeMap } from 'rxjs/operators';
import * as styles from './my-passes.module.scss';

export default function MyPassesPage({ pageContext }: PageProps) {
  const [translation] = useState(new TranslationService(pageContext));
  const [passes, setPasses] = useState([] as PassModel[]);
  const [products, setProducts] = useState({} as { [id: string]: ProductModel; });
  const { isAuthenticated } = useAuthStatus();

  const durationNames = {
    1: '24h',
    2: '48h',
    3: '72h'
  };

  useEffect(() => {
    const passService = getPassService();
    const productService = getProductService();
    passService.init();
    const subscription = passService.passes$
      .pipe(
        mergeMap(async (passes) => {
          const products = {};
          for(const pass of passes) {
            if(!products[pass.productId]) {
              products[pass.productId] = await productService.getProduct(pass.productId);
            }
          }

          return {
            passes: passes,
            products: products
          };
        })
      )
      .subscribe(data => {
        setPasses(data.passes.sort((a, b) => a.activeFrom.getTime() - b.activeFrom.getTime()));
        setTimeout(() => setProducts(data.products), 100);
      });

    return () => {
      subscription.unsubscribe();
    };
  });

  return (
    <TranslationProvider value={translation}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{translation.translate('helmet.my_passes_title')}</title>
        <meta name="description" content={translation.translate('helmet.my_passes_description')} />
        <meta http-equiv="content-language" content={translation.lang} />
      </Helmet>
      <Header></Header>
      <div className={`scrollable ${styles.scrollable}`}>
        <div className={styles.wrapper}>
          <div className={styles.passes__wrapper + ' app__wrapper'}>
            {isAuthenticated ?
              <Fragment>
                <h1 className={styles.heading}>{translation.translate('my_passes.heading')}</h1>
                <p className={styles.text}>{translation.translate('my_passes.text')}</p>
              </Fragment>
              :
              null}

            {!isAuthenticated ?
              <div className={styles.funnelContainer}>
                <SignupFunnelCard
                  actionKey="my_passes"></SignupFunnelCard>
              </div>
              : null}

            {isAuthenticated ?
              <Fragment>
                <div className={styles.passesContainer}>
                  {passes.map((pass, index) => {
                    const product = products[pass.productId];
                    if(!product) return null;
                    const productName = product.title[translation.lang];
                    const ticketType = `${durationNames[pass.duration]} ${productName}`;
                    const travelers = `${pass.travelerCount.travelerCountAdults} ${translation.translate('pass.adults')}, ${pass.travelerCount.travelerCountYoungAdults || 0} ${translation.translate('pass.young_adults')}, ${pass.travelerCount.travelerCountChildren} ${translation.translate('pass.children')}`;

                    return (
                      <Pass
                        key={index}
                        heading={productName}
                        icon={null}
                        ticketType={ticketType}
                        travelers={travelers}
                        startDate={pass.activeFrom}
                        endDate={pass.activeTo}
                        ticketNumber=""
                        color="blue"></Pass>
                    );
                  })}
                </div>
                <div className={styles.spacer}></div>
              </Fragment>
              : null}
          </div>
        </div>

        <Footer></Footer>
      </div>
      <Navbar></Navbar>
    </TranslationProvider>
  );
}