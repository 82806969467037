// extracted by mini-css-extract-plugin
export var container = "pass-module--container--1xTfd";
export var inner = "pass-module--inner--1CC7R";
export var heading = "pass-module--heading--3z1Fy";
export var subHeading = "pass-module--sub-heading--3ngPa";
export var ticketHeader__container = "pass-module--ticket-header__container--1GoMg";
export var wave = "pass-module--wave--1Ir7t";
export var omsenLogo = "pass-module--omsen-logo--Mfg6W";
export var todoAlandQR = "pass-module--todo-aland-QR--2JfTj";
export var ticketInformation__container = "pass-module--ticket-information__container--2KZo7";
export var ticketInformation__single = "pass-module--ticket-information__single--3lzYj";
export var ticketInformation__row = "pass-module--ticket-information__row--1gzdR";
export var divider = "pass-module--divider--gllW1";
export var ie9 = "pass-module--ie9--2i4CH";
export var qrCode__container = "pass-module--qr-code__container--2WuDv";
export var qrCode = "pass-module--qr-code--XMmKL";