import { useTranslation } from '@/src/contexts/translation.context';
import QRCode from 'qrcode.react';
import React from 'react';
import * as styles from './pass.module.scss';
import { format, Locale } from 'date-fns';
import Logo from '@/assets/icons/logo.inline.svg';
import OmsenUrl from '@/assets/images/omsen_logo.png';

type PassProps = {
  heading: string;
  icon: JSX.Element;
  ticketType: string;
  startDate: Date;
  endDate: Date;
  travelers: string;
  ticketNumber: string;
  color: 'blue' | 'red' | 'purple';
};

export function Pass({ heading, color, ticketType, startDate, endDate, travelers, ticketNumber }: PassProps) {
  const translation = useTranslation();

  return (
    <div className={`${styles.container} ${color}`}>
      <div className={`${styles.inner} ${styles.ticketHeader__container}`}>
        <h2 className={styles.heading}>{heading}</h2>
        <h3 className={styles.subHeading}>
          <Logo></Logo>
        </h3>
        <div className={styles.ticketInformation} style={{ marginTop: '1rem' }}>

          <div className={styles.omsenLogo}>
            <img src={OmsenUrl}></img>
          </div>

          <div className={styles.todoAlandQR}>
            <QRCode value="https://todoaland.com"></QRCode>
          </div>

          {/* <div className={styles.ticketInformation__row}>
            <strong>{translation.translate('pass.ticket_type')}</strong>
            {ticketType}
          </div>

          {/* <div className={styles.ticketInformation__row}>
            <strong>{translation.translate('pass.start_date')}</strong>
            21.05.2021
          </div> */}

          {/* <div className={styles.ticketInformation__row}>
          </div> */}

          {/* <div className={styles.ticketInformation__row}>
            <strong>{translation.translate('pass.ticket_number')}</strong>
            {ticketNumber}
          </div> */}

        </div>

        <div className={styles.wave}>
          <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ width: '100%', height: '100%' }}><path d="M-1.97,84.38 C114.27,141.61 373.30,-21.20 505.92,24.17 L500.00,150.00 L0.00,150.00 Z" style={{ stroke: 'none', fill: '#64A2FF' }}></path></svg>
        </div>
      </div>

      <div className={styles.divider}></div>

      <div className={`${styles.inner} ${styles.ticketInformation__container}`}>
        <div className={styles.ticketInformation__single}>
          <div className={styles.ticketInformation__row}>
            <div>
              {translation.translate('pass.ticket_type')}
              <strong>{ticketType}</strong>
            </div>
            <div>
              {translation.translate('pass.travelers')}
              <strong>{travelers}</strong>
            </div>
          </div>
          <div className={styles.ticketInformation__row}>
            <div>
              {translation.translate('pass.start_date')}
              <strong>{format(startDate, 'dd/MM/yyyy')}</strong>
            </div>
            <div>
              {translation.translate('pass.end_date')}
              <strong>{format(endDate, 'dd/MM/yyyy')}</strong>
            </div>
          </div>
        </div>
        {/* <div className={styles.qrCode__container}>
          <div className={styles.qrCode}>
            <QRCode value={ticketNumber}></QRCode>
          </div>
        </div> */}
      </div>
    </div>
  );
}