import { isAfter, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import firebase from 'gatsby-plugin-firebase';
import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { PassModel } from '../models/pass.model';
import { AuthService } from './auth.service';

class PassService {
  initialized: boolean = false;
  passes: PassModel[] = [];
  passes$: Subject<PassModel[]> = new BehaviorSubject([]);
  unsubscribe$: Subject<void> = new Subject();

  init() {
    if(this.initialized) return;
    this.initialized = true;

    if(new AuthService().isLoggedIn()) {
      this.subscribeToPasses(firebase.auth().currentUser);
    }

    firebase.auth().onAuthStateChanged(user => {
      if(user) {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.subscribeToPasses(user);
      } else {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
      }
    });
  }

  subscribeToPasses(user: any) {
    this.unsubscribe$ = new Subject();

    const dateNow = zonedTimeToUtc(new Date(), 'Europe/Helsinki');

    const unsubscribeFirebase = firebase.firestore().collection('users').doc(user.uid).collection('tickets').onSnapshot((changes) => {
      this.unsubscribe$.pipe(take(1)).subscribe(() => unsubscribeFirebase());
      const tickets = changes.docs;

      const passes = tickets.map(ticket => ({
        activeFrom: ticket.data().activeFrom.toDate(),
        activeTo: ticket.data().activeTo.toDate(),
        duration: ticket.data().duration,
        productId: ticket.data().productId,
        travelerCount: ticket.data().travelerCount
      }));

      this.passes = passes.filter((p) => {
        return isAfter(p.activeTo, dateNow);
      });

      this.passes$.next(this.passes);
    });
  }
}

let passServiceInstance = new PassService();
export function getPassService() {
  return passServiceInstance;
}